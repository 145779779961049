import React from 'react'
import Layout from '../components/Layout'
import styled from 'styled-components'
import { Link } from 'gatsby'

const StyledText = styled.div`
  text-align: center;
  justify-content: center;
`

const NotFoundPage = () => (
  <Layout>
    <StyledText>
      <h1>Page not found...</h1>
      <p>You just hit a route that doesn&#39;t exist.</p>
      <Link to="/">
        <h2 color="primary">Go to frontpage</h2>
      </Link>
    </StyledText>
  </Layout>
)

export default NotFoundPage
